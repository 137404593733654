

body {
  overflow-x: hidden;
    background: #232F3E;
    color: #fff;
    font-size: 14px;
    line-height: 1.4;
    font-family: -apple-system,
      BlinkMacSystemFont,
      "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell,
      "Open Sans",
      "Helvetica Neue",
      sans-serif;
  
  }
  
  .form-group {
    margin-top: 16px;
  }
  
  .form {
    max-width: 320px;
    margin: 32px auto;
  }
  
  input {
    background: #333;
    border: 0;
    padding: 16px;
    width: 100%;
    border-radius: 6px;
    color: #fff;
    width: 290px;
  }
  
  input[type="file"] {
    background: transparent;
    padding-left: 0;
  }

  textarea {
    width: 290px;
    color: #000;
  }
  
.send-form-button {
    background-color: #fff;
    width: 290px;
    display: inline-block;
    border: 0;
    padding: 10px;
    color: #000;
    border-radius: 6px;
  }
  

  .header-logo {
    grid-area: logo;
    font-weight: 600;
    font-size: 25px;
    text-decoration: none;
    color: #fff;
  }
